<template>
  <div>
    <div class="table-set">
      <el-table
        :data="tableData"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="編號"
          prop="sno"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="時間"
          prop="sno"
          width="150">
          <template slot-scope="scope">
            {{scope.row.datetime_create ? moment(scope.row.datetime_create).format('YYYY-MM-DD hh:mm') : '-'}}
          </template>
        </el-table-column>
        <el-table-column
          label="問題類型"
          prop="type"
          width="120">
        </el-table-column>
        <el-table-column
          label="Email帳號"
          prop="email"
          width="280">
        </el-table-column>
        <el-table-column
          label="描述"
          prop="description">
        </el-table-column>
        <el-table-column
          label="營幕截圖"
          width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.image_path">
              <!-- 查看
              <a
                href="#"
                style="margin-left: 4px;"
                @click="reviewImage(scope.row.image_path)">
                <font-awesome-icon :icon="[ 'fas', 'el-icon-search' ]" />
              </a> -->
              <el-image 
                style="width: 100px; height: 100px"
                :src="scope.row.image"
                :preview-src-list="[scope.row.image]">
              </el-image>
            </span>
            <span v-else>
              -
            </span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="deleteItem(scope.row.sno)">
              <i class="fas fa-trash-alt"></i>
              刪除
            </el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as apis from '@/apis/index'
import routerService from '@/utils/routerService.js'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'manageReport',
  data () {
    return {
      moment,
      pageSize: 10,
      currentPage: 1,
      tableData: [],
      previewUrl: '' // 預覽圖片base64
    }
  },
  computed: {
    ...mapState([
      'userLimits'
    ]),
    ...mapGetters([
      'getterIsManager',
    ]),
  },
  methods: {
    async search () {
      const result = await apis.reports({
        "page_limit": 20,
        "page_num": this.currentPage,
        "by": "datetime_create",
        "order": "asc"
      })
      if (!result.payload || !result.payload.search_result) {
        return
      }
      this.tableData = result.payload.search_result
    },
    reviewImage (url) {
      this.previewUrl = url
    }
  },
  created () {
    // 無權限則回首頁
    if (this.userLimits == null || this.getterIsManager === false) {
      // this.$router.replace({ name: 'home' })
      routerService('replace', { name: 'home' })
    }

    this.search()
  }
}
</script>

<style lang="scss" scope="true">
// 圖片預覽關閉圖片的叉叉
.el-icon-circle-close {
  color: white;
}
</style>