import router from '@/router/index.js'
import mainRoutes from '@/router/mainRoutes'
import store from '@/store/index.js'
import * as apis from '@/apis/index.ts'

// 詢找路由對象的meta
const findMeta = function (routeList, key, keyName) {
  for (let item of routeList) {
    if (item[key] === keyName) {
      return item.meta
    }
    if (item.children) {
      let result = findMeta(item.children, key, keyName)
      if (result) {
        return result
      }
    }
  }
  return null
}

export default async function (method, options) {
  const originQueryString = JSON.stringify(options.query)
  let routeOptions = JSON.parse(JSON.stringify(options))
  // 清空查詢參數（避免未跳頁前觸發不必要的監聽事件）
  // store.commit('mutationOriginRouteQuery', null)
  // 判斷是否要把query編碼
  try {
    // 詢找路由對象的meta
    let key = ''
    let keyName = ''
    if (routeOptions.name) {
      key = 'name'
      keyName = routeOptions.name
    } else if (routeOptions.path) {
      key = 'path'
      keyName = routeOptions.path
    }
    let meta = findMeta(mainRoutes, key, keyName)
    // 判斷是否要編碼
    if (meta && meta.queryEncode === true) {
      // query編碼
      const result = await apis.encode({ value: originQueryString })
      if (result.success === true) {
        routeOptions.query = {
          query: result.key
        }
      }
    }
  } catch (e) { throw new Error(e) }
  // 執行路由方法
  if (method === 'push') {
    // router.push({
    //   name: 'transition',
    //   query: {
    //     originQuery: originQueryString,
    //     options: JSON.stringify(routeOptions)
    //   }
    // })
    router.push(routeOptions)
  } else if (method === 'replace') {
    // router.replace({
    //   name: 'transition',
    //   query: {
    //     originQuery: originQueryString,
    //     options: JSON.stringify(routeOptions)
    //   }
    // })
    router.replace(routeOptions)
  }
  
}
